import { UseQueryResult } from '@tanstack/react-query'
import { BEACON_ID } from '../common/constants'
import { useI18n } from '@shopify/react-i18n'

import { formatTimestamp } from '@/common/datetime'

export const isValidHex = (color: string) => {
  if (!color || typeof color !== 'string') return false

  // Validate hex values
  //if (color.substring(0, 1) === '#') color = color.substring(1)

  return /^#([a-fA-Z0-9]{6}|[a-fA-Z0-9]{3})$/.test(color)
}

export const initBeacon = (data: { 'app-plan': string; 'shop-timezone': string }) => {
  //Load live chat bubble
  window.Beacon('config', { hideFABOnMobile: true })
  window.Beacon('init', BEACON_ID)
  window.Beacon('identify', {
    'shop-id': shopify.config.shop,
    ...data,
  })
}

export const openBeacon = (screen: 'ANSWERS' | 'ASK') => {
  window.Beacon('open')

  if (screen === 'ASK') {
    window.Beacon('navigate', '/ask')
  }

  if (screen === 'ANSWERS') {
    window.Beacon('navigate', '/answers')
  }
}

export const formatPrice = (price: number, currency: string = 'USD') => {
  const [i18n] = useI18n() // eslint-disable-line react-hooks/rules-of-hooks

  return i18n.formatCurrency(price, {
    currency: currency ?? 'USD',
  })
}

export const formatNumber = (number: number, precision: number = 0) => {
  const [i18n] = useI18n() // eslint-disable-line react-hooks/rules-of-hooks

  return i18n.formatNumber(number, {
    precision,
  })
}

export function getDirtyFields<DirtyFields extends Record<string, unknown>, Values extends Record<keyof DirtyFields, unknown>>(
  dirtyFields: DirtyFields,
  values: Values
): Partial<typeof values> {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) return prev

    return {
      ...prev,
      [key]: typeof dirtyFields[key] === 'object' ? getDirtyFields(dirtyFields[key] as DirtyFields, values[key] as Values) : values[key],
    }
  }, {})

  return dirtyValues
}

// export const buildAdminUrlOld = (shopId: string) => {
//   // Remove '.myshopify.com' from the shopId
//   const storeName = shopId.replace('.myshopify.com', '')

//   // Insert the storeName into the URL
//   const adminUrl = `https://admin.shopify.com/store/${storeName}/apps/early-bird-dev/`

//   return adminUrl
// }

export const buildAdminUrl = (path?: string, isAppURL: boolean = true) => {
  const storeName = shopify.config.shop.split('.')[0]

  let url = `https://admin.shopify.com/store/${storeName}`

  if (isAppURL) {
    url += `/apps/${import.meta.env.VITE_APP_APP_API_KEY}`
  }

  if (path) {
    url += `/${path}`
  }

  return url
}

export const buildAPIUrl = (path: string, searchParams?: URLSearchParams) => {
  const baseURL = import.meta.env.VITE_APP_API_URL
  const url = new URL(`${baseURL}/${path}`)

  if (!searchParams) {
    searchParams = new URLSearchParams()
  }

  searchParams.set('shop', shopify.config.shop)
  url.search = searchParams.toString()

  return url.toString()
}

interface ReplaceObject {
  find: string
  replace: string
}

export const replaceInTemplate = (template: string, replacements: ReplaceObject[]): string => {
  let result = template

  replacements.forEach(({ find, replace }) => {
    if (replace) {
      result = result.replace(new RegExp(find, 'g'), replace)
    }
  })

  return result
}

/**
 * Check an array of quries and return true if any of them are loading
 */
export const isQueryLoading = (queries: UseQueryResult[]): boolean => {
  return queries.some((query) => query.isLoading)
}

export const compareProducts = <T extends { id: string; variants: { id: string }[] }>(
  existingProducts: T[],
  formProducts: T[]
): { newProducts: T[]; deletedProducts: T[] } => {
  const newProducts: T[] = []
  const deletedProducts: T[] = []

  formProducts.forEach((formProduct) => {
    const existingProduct = existingProducts.find((product) => product.id === formProduct.id)
    if (!existingProduct) {
      newProducts.push(formProduct)
    } else {
      const newVariants = formProduct.variants.filter((variant) => !existingProduct.variants.some((v) => v.id === variant.id))
      const deletedVariants = existingProduct.variants.filter((variant) => !formProduct.variants.some((v) => v.id === variant.id))
      if (newVariants.length) {
        newProducts.push({ ...formProduct, variants: newVariants })
      }
      if (deletedVariants.length) {
        deletedProducts.push({ ...existingProduct, variants: deletedVariants })
      }
    }
  })

  // Find deleted products
  existingProducts.forEach((existingProduct) => {
    const formProduct = formProducts.find((product) => product.id === existingProduct.id)
    if (!formProduct) {
      deletedProducts.push(existingProduct)
    }
  })

  return { newProducts, deletedProducts }
}

export const chartTitleFormatter = (value: string | number | null) => {
  if (!value) return ''
  return formatTimestamp(value.toString(), 'D MMM')
}

export const chartTooltipTitleFormatter = (value: string | number | null) => {
  if (!value) return ''
  return formatTimestamp(value.toString(), 'D MMMM YYYY')
}

export const chartValueFormatter = (value: string | number | null, currency: string = 'USD', digits: number = 0) => {
  let parsedValue = value ?? 0
  if (typeof parsedValue !== 'number') {
    parsedValue = parseFloat(parsedValue.replace(/[^\d.-]/g, '')) || 0
  }
  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: digits,
  }).format(parsedValue)
}

// TODO: Make this stripGid and just loop when needed
export const stripGids = (gids: string[]): string[] => gids.map((id) => id.split('/').pop() || '')
export const stripGid = (gid: string): string | undefined => gid.split('/').pop()

export const getBasePlanName = (planName: string, removeFrequency: boolean = true): string => {
  let result = planName.replace(/\(Test\)/g, '').trim()

  if (removeFrequency) {
    result = result.replace(/\(Annual\)/g, '').trim()
  }

  return result
}
