//import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import * as Sentry from '@sentry/react'

const container = document.getElementById('root')

if (!container) throw new Error('Failed to find the root element')

// Disable logs on production
if (import.meta.env.PROD) {
  const emptyFunction = () => {}
  //console.log = emptyFunction
  console.info = emptyFunction
  console.error = emptyFunction
  console.debug = emptyFunction

  Sentry.init({
    environment: import.meta.env.DEV ? 'development' : 'production',
    dsn: 'https://d7a482aa3727c547dead1eb5cf7f1ac5@o4505362933678080.ingest.us.sentry.io/4507484086206464',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

// TODO: We really should prevent the app from loading if there is no ?shop in the URL.
const root = createRoot(container)
root.render(<App />)
