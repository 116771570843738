// React
import { useEffect } from 'react'

// Hooks
import { useMutateInstall } from '@/hooks/useMutateInstall'

// Components
import { Transition } from '@/components/shared/Transition'
import { Trans, useTranslation } from 'react-i18next'
import { BlockStack, Link, Text } from '@shopify/polaris'
import { useQuery } from '@tanstack/react-query'
import { installStatusQueryOptions, settingsQueryOptions } from '@/common/queryOptions'

const AuthGuard = ({ children }: { children?: JSX.Element }) => {
  const { t } = useTranslation()
  const { mutate, isPending, isSuccess, isError: isInstallError } = useMutateInstall()

  const { isLoading, isError, data } = useQuery(installStatusQueryOptions())
  const { isLoading: isSettingsLoading, isFetched: isSettingsFetched } = useQuery({ ...settingsQueryOptions(), enabled: data?.status || isSuccess })

  const settingsUnresolved = isSettingsLoading || !isSettingsFetched

  useEffect(() => {
    if (data) {
      if (data?.status === false) {
        mutate()
      }
    }
  }, [data])

  // If we're still loading the install status, show a loading spinner
  if (isLoading || settingsUnresolved) {
    return (
      <Transition
        title={t('authGuardAuthenticatingTitle')}
        content={
          <BlockStack gap="200" inlineAlign="center">
            <Text as="p" alignment="center">
              {t('authGuardLoadingLine1')}
              <br />
              {t('authGuardLoadingLine2')}
              <br /> <br />
              <Trans t={t} i18nKey="authGuardLoadingLine3">
                .
                <Link target="_blank" url="https://www.shopside.com.au/docs/earlybird">
                  .
                </Link>
                .
              </Trans>
            </Text>
          </BlockStack>
        }
      />
    )
  }

  // There was an error fetching the install status, show an error message
  if (isError || !data) {
    return (
      <Transition
        isLoading={false}
        title={t('authGuardOhNoTitle')}
        content={
          <BlockStack gap="200" inlineAlign="center">
            <Text as="p" alignment="center">
              {t('authGuardOhNoLine1')}
            </Text>
            <Text as="p" alignment="center">
              {t('authGuardOhNoLine2')}
            </Text>
          </BlockStack>
        }
      />
    )
  }

  // If install process failed with 403 error
  if (isInstallError) {
    return (
      <Transition
        isLoading={false}
        title={t('authGuardBannedTitle')}
        content={
          <BlockStack gap="200" inlineAlign="center">
            <Text as="p" alignment="center">
              {t('authGuardBannedLine1')}
            </Text>
            <Text as="p" alignment="center">
              {t('authGuardBannedLine2')}
            </Text>
          </BlockStack>
        }
      />
    )
  }

  // If we're running the install mutation, show a loading spinner
  if (isPending || (data?.status === false && !isSuccess) || settingsUnresolved) {
    return (
      <Transition
        title={t('authGuardInstallingTitle')}
        content={
          <BlockStack gap="200" inlineAlign="center">
            <Text as="p" alignment="center">
              {t('authGuardLoadingLine1')}
            </Text>
            <Text as="p" alignment="center">
              {t('authGuardLoadingLine2')}
            </Text>
            <Text as="p" alignment="center">
              <Trans t={t} i18nKey="authGuardLoadingLine3">
                .
                <Link target="_blank" url="https://www.shopside.com.au/docs/earlybird">
                  .
                </Link>
                .
              </Trans>
            </Text>
          </BlockStack>
        }
      />
    )
  }

  return children
}

export { AuthGuard }
