import { BlockStack, Box, Card, InlineStack, Layout, Page, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const Error = () => {
  const { t } = useTranslation()

  return (
    <div className="error-page">
      <Page narrowWidth>
        <Layout>
          <Layout.Section>
            <Box>
              <Card>
                <InlineStack gap="600">
                  <BlockStack gap="600" align="center">
                    <Text as="h3" variant="headingXl">
                      {t('errorPageTitle')}
                    </Text>
                    <Text as="p" variant="bodyLg" tone="subdued">
                      {t('errorPageDescription')}
                    </Text>
                  </BlockStack>
                </InlineStack>
              </Card>
            </Box>
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  )
}

export { Error }
