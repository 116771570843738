import { SectionTitle } from '@/components/shared/SectionTitle'
import { BlockStack, Box, Card, Grid, InlineGrid, InlineStack, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import dayjs from '@/common/datetime'

const PlansFallback = () => {
  const { t } = useTranslation()

  return (
    <SkeletonPage title={t('appPlansTitle')}>
      <Layout>
        <Layout.Section>
          <SectionTitle
            title={t('usageStatsTitle')}
            description={t('usageStatsSubtitle', {
              from: dayjs.utc().startOf('month').format('MMMM D'),
              to: dayjs.utc().endOf('month').format('MMMM D'),
            })}
          />
          <Card padding="400">
            <BlockStack gap="400" align="center" inlineAlign="stretch">
              <Grid
                columns={{
                  xs: 4,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  xl: 4,
                }}
              >
                <Grid.Cell
                  columnSpan={{
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                  }}
                >
                  <BlockStack gap="200">
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText lines={2} />
                  </BlockStack>
                </Grid.Cell>

                <Grid.Cell
                  columnSpan={{
                    xs: 3,
                    sm: 3,
                    md: 3,
                    lg: 3,
                    xl: 3,
                  }}
                >
                  <Box width="100%">
                    <InlineGrid columns={2} gap="400">
                      <BlockStack gap="200">
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText lines={2} />
                      </BlockStack>
                      <BlockStack gap="200">
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText lines={2} />
                      </BlockStack>
                    </InlineGrid>
                  </Box>
                </Grid.Cell>
              </Grid>
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Box paddingBlockEnd="200">
            <InlineStack align="space-between">
              <SectionTitle title={t('appPlansAvailableTitle')} description={t('appPlansAvailableSubtitle')} />
            </InlineStack>
          </Box>
          <Grid columns={{ xs: 6, sm: 6, md: 12, lg: 12 }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={6} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={6} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={6} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={6} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={6} />
                </BlockStack>
              </Card>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={6} />
                </BlockStack>
              </Card>
            </Grid.Cell>
          </Grid>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}

export { PlansFallback }
