import { Card, Layout, Page, Spinner, Text, BlockStack } from '@shopify/polaris'

type Props = {
  title: string
  content?: React.ReactNode
  isLoading?: boolean
}

const Transition = ({ title, content, isLoading = true }: Props) => {
  return (
    <Page narrowWidth>
      <Layout>
        <Layout.Section>
          <Card>
            <BlockStack gap="400" inlineAlign="center">
              <BlockStack gap="200" inlineAlign="center">
                {isLoading && (
                  <div>
                    <Spinner size="large" accessibilityLabel={title} />
                  </div>
                )}
                <Text as="h1" variant="headingLg">
                  {title}
                </Text>
              </BlockStack>
              {content}
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export { Transition }
